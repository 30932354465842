import React from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import logo from "../images/croness_logo_white.png";
import facebook from "../images/social/facebook.png";
import instagram from "../images/social/instagram.png";
import tiktok from "../images/social/tik-tok.png";
import twitter from "../images/social/twitter.png";
import linkedin from "../images/social/linkedin.png";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <Row className="footer-row">
          <Col lg={4} md={6}>
            <Card className="footer-card">
              <Card.Body>
                <Card.Img
                  className="footer-logo footer-title"
                  loading="lazy"
                  src={logo}
                  alt="croness-logo"
                />
                <Card.Text className="footer-try-text">
                  <span>
                    Try FREE for 14 days, <br /> with no obligation!
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} className="policy-card">
            <Card className="footer-card">
              <Card.Body>
                <Card.Text>
                  <span className="footer-title">Our Policy</span>
                  <Nav.Link as={Link} to="/terms-and-conditions">
                    Terms and conditions
                  </Nav.Link>
                  <Nav.Link as={Link} to="/privacy-policy">
                    Privacy policy
                  </Nav.Link>
                  <Nav.Link as={Link} to="/cookies-policy">
                    Cookies policy
                  </Nav.Link>
                  <Nav.Link as={Link} to="/GDPR-policy">
                    GDPR
                  </Nav.Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6}>
            <Card className="footer-card">
              <Card.Body>
                <Card.Text className="">
                  <span className="footer-title">Follow us</span>
                </Card.Text>
                <div className="social-icons">
                  <Nav.Link
                    href="https://www.facebook.com/CronessCalendar"
                    rel="noopener nofollow blank"
                    target="_blank"
                  >
                    <Card.Img
                      loading="lazy"
                      src={facebook}
                      alt="facebook"
                      aria-label="facebook"
                    />
                  </Nav.Link>

                  <Nav.Link
                    href="https://www.instagram.com/croness.calendar/"
                    rel="noopener nofollow blank"
                    target="_blank"
                  >
                    <Card.Img
                      loading="lazy"
                      src={instagram}
                      alt="instagram"
                      aria-label="instagram"
                    />
                  </Nav.Link>
                  <Nav.Link
                    href="https://www.linkedin.com/company/croness"
                    rel="noopener nofollow blank"
                    target="_blank"
                  >
                    <Card.Img
                      loading="lazy"
                      src={linkedin}
                      alt="linkedin"
                      aria-label="linkedin"
                    />
                  </Nav.Link>
                  <Nav.Link
                    href="https://www.tiktok.com/@croness.calendar"
                    rel="noopener nofollow blank"
                    target="_blank"
                  >
                    <Card.Img
                      loading="lazy"
                      src={tiktok}
                      alt="tiktok"
                      aria-label="tiktok"
                    />
                  </Nav.Link>

                  <Nav.Link
                    href="https://twitter.com/CronessCalendar"
                    rel="noopener nofollow blank"
                    target="_blank"
                  >
                    <Card.Img
                      loading="lazy"
                      src={twitter}
                      alt="twitter"
                      aria-label="twitter"
                    />
                  </Nav.Link>
                </div>

                <a
                  className="contactToEmail"
                  rel="noopener nofollow blank"
                  href="mailto:contact@croness.ro"
                >
                  contact@croness.ro
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="copyright">
          &copy;2023{" "}
          <a
            href="https://croness.ro/"
            rel="noopener nofollow blank noreferrer"
          >
            CRONESS
          </a>{" "}
          - Toate drepturile rezervate
        </div>
      </div>
    </div>
  );
};

export default Footer;
