import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Container } from "react-bootstrap";
import { easeQuadInOut } from "d3-ease";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import Card from "react-bootstrap/Card";
import ladySendigEmail from "../images/lady-sending-email.png";
import checkMark from "../images/check-mark.png";
import "../styles/Contact.css";
import Footer from "../components/Footer";
import AnimatedProgressProvider from "./AnimatedProgressProvider";

const Contact = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <div className="form-container">
        <Container>
          <Row>
           <Col lg={6} md={12} className="text-col">
              <h1 className="form-title">We're here for you!</h1>
              <p className="first-paragraph">
                In order to save time, we provide you with a list of the most
                frequently asked questions we've received from you!
              </p>
              <h2 className="form-subtitle">
                On which devices can I use Croness?
              </h2>
              <p className="form-paragraph">
                Croness is a browser-based application that can be used on any
                device, such as phone, tablet, laptop, or PC, regardless of the
                operating system (Windows, MacOS, Linux) as long as you have an
                internet connection.
              </p>
              <hr></hr>
              <h2 className="form-subtitle">Custom plans</h2>
              <p className="form-paragraph">
                <em>
                  "I want to choose a specific plan, but I have more employees
                  than the plan allows. What should I do?"
                </em>{" "}
                Don't worry, we're here to help. You can contact us and we will
                create a customized plan based on the number of employees, the
                number of SMS messages or emails needed, or any other specific
                requirements. It's important for us to provide quality services
                and help you succeed.
              </p>
              <hr></hr>
              <h2 className="form-subtitle">Online appointments for clients</h2>
              <p className="form-paragraph">
                <em>
                  "Can my clients make online appointments by themselves?"
                </em>{" "}
                Currently, this option is not available, but with the release of
                version 2.0 of our platform, the ability for clients to make
                online appointments on their own will be introduced at no
                additional cost for Pro, Premium, and Business plans.
              </p>
            </Col>
            <Col lg={6} md={12}>
              <Card>
                <Card.Body>
                  <Formik
                    className
                    initialValues={{ name: "", email: "", message: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.name) {
                        errors.name = "Name Required";
                      }
                      if (!values.email) {
                        errors.email = "Email Required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      if (!values.message) {
                        errors.message = "Message Required";
                      }
                      return errors;
                    }}
                    onSubmit={(values, actions) => {
                      setTimeout(() => {
                        console.log(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                        actions.resetForm({ values: "" });
                      }, 400);
                      setShow(true);
                    }}
                  >
                    {({ handleBlur, isSubmitting }) => (
                      <Form className="contact-form">
                        <label htmlFor="name">
                          Name <span className="asterix">*</span>
                        </label>
                        <div className="form-wrapper">
                          <Field type="text" name="name" onBlur={handleBlur} />

                          <ErrorMessage
                            name="name"
                            className={"inputError"}
                            component={"span"}
                          />
                        </div>

                        <label htmlFor="email">
                          Email <span className="asterix">*</span>
                        </label>
                        <div className="form-wrapper">
                          <Field
                            type="email"
                            name="email"
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="email"
                            className={"inputError"}
                            component={"span"}
                          />
                        </div>

                        <label htmlFor="message">
                          Message <span className="asterix">*</span>
                        </label>
                        <div className="form-wrapper">
                          <Field
                            type="textarea"
                            name="message"
                            component="textarea"
                            onBlur={handleBlur}
                            rows="4"
                            cols="80"
                          />
                          <ErrorMessage
                            name="message"
                            className={"textareaError"}
                            component={"span"}
                          />
                        </div>
                        <button
                          type="submit"
                          id="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button>
                        <Toast
                          onClose={() => setShow(false)}
                          show={show}
                          delay={4000}
                          className="toast-show"
                        >
                          <Toast.Header>
                            <AnimatedProgressProvider
                              valueStart={0}
                              valueEnd={100}
                              duration={4}
                              easingFunction={easeQuadInOut}
                            >
                              {(value) => {
                                const roundedValue = Math.round(value);
                                return (
                                  <CircularProgressbar
                                    value={value}
                                    text={`${roundedValue}%`}
                                    /* This is important to include, because if you're fully managing the
        animation yourself, you'll want to disable the CSS animation. */
                                    styles={buildStyles({
                                      pathTransition: "none",
                                    })}
                                  />
                                );
                              }}
                            </AnimatedProgressProvider>

                            <img
                              src={checkMark}
                              className="rounded check-mark"
                              alt="check-mark"
                            />
                            <strong className="me-auto">THANK YOU</strong>
                          </Toast.Header>
                          <Toast.Body>You're message has been sent!</Toast.Body>
                        </Toast>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
              <img
                className="lady-email"
                src={ladySendigEmail}
                alt="ladySendigEmail"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
