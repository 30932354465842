import "./styles/App.css";
import NavbarComp from "./pages/NavbarComp";

const App = () => {
  return (
    <div className="App">
      <NavbarComp />
    </div>
  );
};

export default App;
