import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "../../styles/PriceCards.css";
import included from "../../images/included-price.png";
import notIncluded from "../../images/not-included-price.png";
import ListGroup from "react-bootstrap/ListGroup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PriceCards = () => {
  const [isChecked, setIsChecked] = useState(false);

  const priceTargetingFree = useRef(null);
  const priceTargetingBasic = useRef(null);
  const priceTargetingPro = useRef(null);
  const priceTargetingPremium = useRef(null);
  const priceTargetingBusiness = useRef(null);

  const prices = [
    priceTargetingFree,
    priceTargetingBasic,
    priceTargetingPro,
    priceTargetingPremium,
    priceTargetingBusiness,
  ];

  // useEffect(() => {
  //   function handleClick(event) {
  //     const parentElement = event.target.closest("[id]");
  //     const priceChosen = parentElement.querySelector(".price").innerHTML;
  //     if (parentElement) {
  //       console.log(parentElement.id + " @ " + priceChosen);
  //     }
  //   }
  //   if (prices.map((value) => value.current)) {
  //     prices.map((value) =>
  //       value.current.addEventListener("click", handleClick)
  //     );
  //   }
  // }, []);

  useEffect(() => {
    const handleClick = (event) => {
      const parentElement = event.target.closest("[id]");
      const priceChosen = parentElement.querySelector(".price").innerHTML;

      console.log(parentElement.id + " @ " + priceChosen);
    };

    prices.forEach((price) => {
      if (price.current) {
        price.current.addEventListener("click", handleClick);
      }
    });
  }, []);

  var settings = {
    slidesToShow: 5,
    arrows: false,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          infinite: true,
          centerMode: true,
          className: "center",
          centerPadding: "60px",
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          dots: true,
          infinite: true,
          centerMode: true,
          className: "center",
          centerPadding: "60px",
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          infinite: true,
          centerMode: true,
          className: "center",
          centerPadding: "60px",
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          dots: true,
          infinite: true,
          centerMode: true,
          className: "center",
          centerPadding: "30px",
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="price-cards-container">
      <Container className="custom-price-container">
        <Container>
          <h1>Choose the right plan</h1>
          <h3>
            Try <span className="freeText">FREE</span> for{" "}
            <span className="circle">14</span> days and see the difference. No
            obligations!
          </h3>
        </Container>
        <Form className="switch">
          <span className="monthlyAndAnnually">
            <em>MONTHLY</em>
          </span>
          <Form.Check
            type="switch"
            id="custom-switch"
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <span className="monthlyAndAnnually">
            <em>ANNUAL</em>
          </span>
        </Form>
        <Row>
          <Slider {...settings}>
            <Col
              lg={15}
              md={12}
              className="card-column"
              ref={priceTargetingFree}
            >
              <Link to="/sign-up" id="free">
                <Card className="master-card master-card-free">
                  <div variant="top" className="free"></div>
                  <Card.Body>
                    <ListGroup variant="flush">
                      <Card.Title>Free</Card.Title>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Maximum&nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          2
                        </span>{" "}
                        &nbsp;employees
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Unlimited appointments
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Employee management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Client management
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        Email notifications
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        SMS notifications
                      </ListGroup.Item>

                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        Service list
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        SMS confirmations
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        Calendar synchronization
                      </ListGroup.Item>
                    </ListGroup>
                    <div className="price-and-button">
                      <Button
                        variant="primary"
                        className="price-button free-button"
                      >
                        Start NOW
                      </Button>
                      <Card.Text className="price-card-text">
                        <sup>￡</sup>
                        <span className="price">0</span>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col
              lg={15}
              md={12}
              className="card-column"
              ref={priceTargetingBasic}
            >
              <Link to="/sign-up" id="basic">
                <Card className="master-card master-card-basic">
                  <div variant="top" className="basic"></div>
                  <Card.Body>
                    <ListGroup variant="flush">
                      <Card.Title>Basic</Card.Title>
                      {isChecked ? (
                        <span className="pay-annually">
                          <em>yearly charge</em>
                        </span>
                      ) : (
                        ""
                      )}
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Between&nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          2 - 4
                        </span>{" "}
                        &nbsp;employees
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Unlimited appointments
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Employee management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Client management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Email notifications -&nbsp;{" "}
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          {" "}
                          2000
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        SMS notifications
                      </ListGroup.Item>

                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        Service list
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        SMS confirmations
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        Calendar synchronization
                      </ListGroup.Item>
                    </ListGroup>
                    <div className="price-and-button">
                      <Button
                        variant="primary"
                        className="price-button basic-button"
                      >
                        Try for FREE
                      </Button>
                      <Card.Text className="price-card-text">
                        <sup>￡</sup>
                        {isChecked ? (
                          <span className="price">23</span>
                        ) : (
                          <span className="price">29</span>
                        )}

                        <span> / mo</span>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col
              lg={15}
              md={12}
              className="card-column"
              ref={priceTargetingPro}
            >
              <Link to="/sign-up" id="pro">
                <Card className="master-card master-card-pro">
                  <div variant="top" className="pro"></div>
                  <Card.Body>
                    <div className="populat-tag">Popular</div>
                    <ListGroup variant="flush">
                      <Card.Title>Pro</Card.Title>
                      {isChecked ? (
                        <span className="pay-annually">
                          <em>yearly charge</em>
                        </span>
                      ) : (
                        ""
                      )}
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Between&nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          5 - 8
                        </span>{" "}
                        &nbsp;employees
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Unlimited appointments
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Employee management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Client management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Email notifications -&nbsp;{" "}
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          {" "}
                          6000
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        SMS notifications -&nbsp;{" "}
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          {" "}
                          1500
                        </span>
                      </ListGroup.Item>

                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        Service list
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        SMS confirmations
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        Calendar synchronization
                      </ListGroup.Item>
                    </ListGroup>
                    <div className="price-and-button">
                      <Button
                        variant="primary"
                        className="price-button pro-button"
                      >
                        Try for FREE
                      </Button>
                      <Card.Text className="price-card-text">
                        <sup>￡</sup>
                        {isChecked ? (
                          <span className="price">77</span>
                        ) : (
                          <span className="price">89</span>
                        )}
                        <span> / mo</span>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col
              lg={15}
              md={12}
              className="card-column"
              ref={priceTargetingPremium}
            >
              <Link to="/contact" id="premium">
                <Card className="master-card master-card-premium">
                  <div variant="top" className="premium">
                    {" "}
                  </div>
                  <Card.Body>
                    <ListGroup variant="flush">
                      <Card.Title>Premium</Card.Title>
                      {isChecked ? (
                        <span className="pay-annually">
                          <em>yearly charge</em>
                        </span>
                      ) : (
                        ""
                      )}
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Between&nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          9 - 15
                        </span>{" "}
                        &nbsp;employees
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Unlimited appointments
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Employee management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Client management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Email notifs -&nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          nelimitat
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        SMS notif -&nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          4000
                        </span>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Service list
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        SMS confirmations
                      </ListGroup.Item>
                      <ListGroup.Item className="greyed-out">
                        <img
                          loading="lazy"
                          src={notIncluded}
                          className="mini-check not-included"
                          alt="notIncluded"
                        />
                        Calendar synchronization
                      </ListGroup.Item>
                    </ListGroup>
                    <div className="price-and-button">
                      <Button
                        variant="primary"
                        className="price-button premium-button"
                      >
                        Try for FREE
                      </Button>
                      <Card.Text className="price-card-text">
                        <sup>￡</sup>
                        {isChecked ? (
                          <span className="price">179</span>
                        ) : (
                          <span className="price">219</span>
                        )}
                        <span> / mo</span>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

            <Col
              lg={15}
              md={12}
              className="card-column"
              ref={priceTargetingBusiness}
            >
              <Link to="/contact" id="business">
                <Card className="master-card master-card-business">
                  <div variant="top" className="business">
                    {" "}
                  </div>
                  <Card.Body>
                    <ListGroup variant="flush">
                      <Card.Title>Business</Card.Title>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Over&nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          15
                        </span>{" "}
                        &nbsp;employees
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Unlimited appointments
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Employee management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Client management
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Email notifs - &nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          nelimitat
                        </span>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        SMS notifs - &nbsp;
                        <span style={{ color: "#143b6d", fontWeight: "800" }}>
                          nelimitat
                        </span>
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Service list
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        SMS confirmations
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <img
                          loading="lazy"
                          src={included}
                          className="mini-check"
                          alt="included-in-price"
                        />
                        Calendar synchronization
                      </ListGroup.Item>
                    </ListGroup>
                    <div className="price-and-button">
                      <Button
                        variant="primary"
                        className="price-button business-button"
                      >
                        Contact us
                      </Button>
                      <Card.Text className="price-card-text">
                        <span className="price business-price">-</span>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Slider>
        </Row>
      </Container>
    </div>
  );
};

export default PriceCards;
