import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/ServicesScroll.css";
import cosmetics from "../../images/businesses/cosmetics.png";
import clinic from "../../images/businesses/clinic.png";
import hair from "../../images/businesses/hair.png";
import legal from "../../images/businesses/legal-document.png";
import dental from "../../images/businesses/tooth.png";
import trainer from "../../images/businesses/male.png";
import barber from "../../images/businesses/baber-shop.png";
import veterinar from "../../images/businesses/veterinary.png";

import driving from "../../images/businesses/driving-school.png";
import spa from "../../images/businesses/spa.png";
import tattoo from "../../images/businesses/tattoo.png";
import service from "../../images/businesses/technician.png";
import architecture from "../../images/businesses/blueprint.png";
import massage from "../../images/businesses/body-massage.png";
import eyelashes from "../../images/businesses/eyelash.png";
import hairRemoval from "../../images/businesses/hair-removal.png";

import psychology from "../../images/businesses/support.png";
import regeneration from "../../images/businesses/regeneration.png";
import piercing from "../../images/businesses/piercings.png";
import gynecology from "../../images/businesses/uterus.png";
import solar from "../../images/businesses/solarium.png";
import accounting from "../../images/businesses/accounting.png";
import nails from "../../images/businesses/nail-polish.png";

import consulting from "../../images/businesses/consulting.png";
import eyes from "../../images/businesses/eye-examination.png";
import makeup from "../../images/businesses/makeup-artist.png";
import microblading from "../../images/businesses/mascara.png";
import fotoVideo from "../../images/businesses/multimedia.png";
import marketing from "../../images/businesses/bullhorn.png";
import more from "../../images/businesses/more.png";

const ServicesScroll = ({ image }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 3000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div className="big-service-scroller">
      <div className="container service-scroller">
        <h1>
          Businesses using <span>Croness</span>
        </h1>
        <p>
          See the full list {""}
          <Button onClick={handleShow} className="full-list-button">
            HERE
          </Button>
        </p>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {/* Our solution is for you: */}
              Croness is perfect for:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="modal-row">
              <Col lg={3} md={6} sm={12}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <img src={cosmetics} loading="lazy" alt="Cosmetics Salon" />
                    Cosmetics Salon
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <img src={clinic} loading="lazy" alt="Medical Clinic" />
                    Medical Clinic
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img
                      src={hair}
                      loading="lazy"
                      alt="Hairstyling and Hairdresser"
                    />
                    Hairstyling and Hairdresser
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={legal} loading="lazy" alt="Notary or Law" />
                    Notary or Law
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={dental} loading="lazy" alt="Dental" />
                    Dental
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={trainer} loading="lazy" alt="Personal Trainers" />
                    Personal Trainer
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={barber} loading="lazy" alt="Barbershop" />
                    Barbershop
                  </ListGroup.Item>
                  <ListGroup.Item className="last-item">
                    <img
                      src={veterinar}
                      loading="lazy"
                      alt="Veterinary Clinic"
                    />
                    Veterinary Clinic
                  </ListGroup.Item>
                </ListGroup>
              </Col>

              <Col lg={3} md={6} sm={12}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <img src={driving} loading="lazy" alt="Driving School" />
                    Driving School
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={spa} loading="lazy" alt="Spa Center" />
                    Spa Center
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={tattoo} loading="lazy" alt="Tattoo Artist" />
                    Tattoo Artist
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img
                      src={service}
                      loading="lazy"
                      alt="Auto Service or Inspection Station"
                    />
                    Car Service
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={architecture} loading="lazy" alt="Architecture" />
                    Architecture
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={massage} loading="lazy" alt="Massage Salon" />
                    Massage Salon
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img
                      src={eyelashes}
                      loading="lazy"
                      alt="Eyelash Extensions - Lashes"
                    />
                    Eyelash
                  </ListGroup.Item>

                  <ListGroup.Item className="last-item">
                    <img
                      src={hairRemoval}
                      loading="lazy"
                      alt="Hair Removal Salon"
                    />
                    Hair Removal Salon
                  </ListGroup.Item>
                </ListGroup>
              </Col>

              <Col lg={3} md={6} sm={12}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <img src={psychology} loading="lazy" alt="Psychology" />
                    Psychology
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img
                      src={regeneration}
                      loading="lazy"
                      alt="Medical Recovery"
                    />
                    Medical Recovery
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={piercing} loading="lazy" alt="Body Piercing" />
                    Body Piercing
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={gynecology} loading="lazy" alt="Gynecology" />
                    Gynecology
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={solar} loading="lazy" alt="Tanning Salon" />
                    Tanning Salon
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={accounting} loading="lazy" alt="Accounting" />
                    Accounting
                  </ListGroup.Item>
                  <ListGroup.Item className="last-item-middle">
                    <img src={nails} loading="lazy" alt="Gel Nails" />
                    Nails
                  </ListGroup.Item>
                </ListGroup>
              </Col>

              <Col lg={3} md={6} sm={12}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <img
                      src={consulting}
                      loading="lazy"
                      alt="Consulting Firms"
                    />
                    Consulting Firms
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img
                      src={eyes}
                      loading="lazy"
                      alt="Optical Medical Clinic"
                    />
                    Optical Medical Clinic
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={makeup} loading="lazy" alt="Makeup Artist" />
                    Makeup Artist
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img
                      src={microblading}
                      loading="lazy"
                      alt="Micropigmentation"
                    />
                    Micropigmentation
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img
                      src={fotoVideo}
                      loading="lazy"
                      alt="Photographers and Videographers"
                    />
                    Photographers and Videographers
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img
                      src={marketing}
                      loading="lazy"
                      alt="Marketing Agency"
                    />
                    Marketing Agency
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <img src={more} loading="lazy" alt="Others" />
                    Others
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <div className="first-block"></div>

        <Slider {...settings}>
          <div>
            <img src={cosmetics} loading="lazy" alt="Cosmetics Salon" />
            Cosmetics Salon
          </div>
          <div>
            <img src={clinic} loading="lazy" alt="Medical Clinic" />
            Medical Clinic
          </div>
          <div>
            <img src={hair} loading="lazy" alt="Hairstyling and Hairdresser" />
            Hairstyling and Hairdresser
          </div>
          <div>
            <img src={legal} loading="lazy" alt="Notary or Law" />
            Notary or Law
          </div>
          <div>
            <img src={dental} loading="lazy" alt="Dental" />
            Dental
          </div>
          <div>
            <img src={trainer} loading="lazy" alt="Personal Trainer" />
            Personal Trainer
          </div>
          <div>
            <img src={barber} loading="lazy" alt="Barbershop" />
            Barbershop
          </div>
          <div>
            <img src={veterinar} loading="lazy" alt="Veterinary" />
            Veterinary
          </div>
          <div>
            <img src={driving} loading="lazy" alt="Driving School" />
            Driving School
          </div>
          <div>
            <img src={spa} loading="lazy" alt="Spa Center" />
            Spa Center
          </div>
          <div>
            <img src={tattoo} loading="lazy" alt="Tattoo Artist" />
            Tattoo Artist
          </div>
          <div>
            <img
              src={service}
              loading="lazy"
              alt="Auto Service or Inspection Station"
            />
            Car Service
          </div>
          <div>
            <img src={architecture} loading="lazy" alt="Architecture" />
            Architecture
          </div>
          <div>
            <img src={massage} loading="lazy" alt="Massage Salon" />
            Massage Salon
          </div>
          <div>
            <img
              src={eyelashes}
              loading="lazy"
              alt="Eyelash Extensions - Lashes"
            />
            Eyelash Extensions
          </div>
          <div>
            <img src={hairRemoval} loading="lazy" alt="Hair Removal Salon" />
            Hair Removal Salon
          </div>
          <div>
            <img src={psychology} loading="lazy" alt="Psychology" />
            Psychology
          </div>
          <div>
            <img src={regeneration} loading="lazy" alt="Medical Recovery" />
            Medical Recovery
          </div>
          <div>
            <img src={piercing} loading="lazy" alt="Body Piercing" />
            Body Piercing
          </div>
          <div>
            <img src={gynecology} loading="lazy" alt="Gynecology" />
            Gynecology
          </div>
          <div>
            <img src={solar} loading="lazy" alt="Tanning Salon" />
            Tanning Salon
          </div>
          <div>
            <img src={accounting} loading="lazy" alt="Accounting" />
            Accounting
          </div>
          <div>
            <img src={nails} loading="lazy" alt="Gel Nails" />
            Nails
          </div>
          <div>
            <img src={consulting} loading="lazy" alt="Consulting Firms" />
            Consulting Firms
          </div>
          <div>
            <img src={eyes} loading="lazy" alt="Medical Optics Clinic" />
            Medical Optics Clinic
          </div>
          <div>
            <img src={makeup} loading="lazy" alt="Makeup Artist" />
            Makeup Artist
          </div>
          <div>
            <img src={microblading} loading="lazy" alt="Micropigmentation" />
            Micropigmentation
          </div>
          <div>
            <img
              src={fotoVideo}
              loading="lazy"
              alt="Photographers and Videographers"
            />
            Photographers and Videographers
          </div>
          <div>
            <img src={marketing} loading="lazy" alt="Marketing Agency" />
            Marketing Agency
          </div>
          <div>
            <img src={more} loading="lazy" alt="Others" />
            Others
          </div>
        </Slider>

        <div className="last-block"></div>
      </div>
      <div id="pricing"></div>
    </div>
  );
};

export default ServicesScroll;
