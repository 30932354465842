import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import miniCheck from "../../images/included-price.png";
import "../../styles/TryNowFree.css";
import manNotify from "../../images/man-receiving-notification2.png";

const TryNowFree = () => {
  return (
    <div className="try-now-big-container">
      <div className="container">
        <Row>
          <Col lg={4} md={12}>
            <Card className="free-card">
              <Card.Img
                className="man-notify"
                loading="lazy"
                src={manNotify}
                alt="notification-received"
              />
            </Card>
          </Col>
          <Col lg={8} md={12}>
            <Card className="free-card">
              <Card.Body>
                <Card.Title className="try-text">
                  <h1 className="try-title">
                    Try <span>FREE</span> <br /> for <span>14</span> days
                  </h1>
                </Card.Title>
                <div className="try-wrapper">
                  <Card.Img
                    loading="lazy"
                    src={miniCheck}
                    alt="check"
                  ></Card.Img>
                  <Card.Text>
                    Discover how you can simplify appointments through a modern
                    and easy-to-use interface
                  </Card.Text>
                </div>

                <div className="try-wrapper">
                  <Card.Img
                    loading="lazy"
                    src={miniCheck}
                    alt="check"
                  ></Card.Img>
                  <Card.Text>
                    Appointments in the calendar will be colored according to
                    the client's response, so you can immediately see their
                    status
                  </Card.Text>
                </div>

                <div className="try-wrapper">
                  <Card.Img
                    loading="lazy"
                    src={miniCheck}
                    alt="check"
                  ></Card.Img>
                  <Card.Text>
                    Ensure meetings are respected with the help of automatic
                    notifications
                  </Card.Text>
                </div>

                <div className="try-wrapper">
                  <Card.Img
                    loading="lazy"
                    src={miniCheck}
                    alt="check"
                  ></Card.Img>
                  <Card.Text>
                    Increase your company's revenue starting today
                  </Card.Text>
                </div>

                <InputGroup className="mb-3 try-input">
                  <Form.Control
                    placeholder="Email address"
                    aria-label="Email address"
                    aria-describedby="basic-addon2"
                  />
                  <Link to="/sign-up">
                    <Button
                      variant="secondary"
                      id="button-addon2"
                      className="try-button"
                    >
                      Start now
                    </Button>
                  </Link>
                </InputGroup>
                <Card.Text className="disclaimer">
                  By entering your personal data, you agree to the privacy
                  policy
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TryNowFree;
