import { React, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import smsReceived from "../../images/sms-received.png";
import customerIsHere from "../../images/customer-is-here.png";
import "../../styles/Animated50.css";

const Animated50 = () => {
  const path = useRef(null);
  const pathTablet = useRef(null);
  const animated = useRef(null);

  useEffect(() => {
    const handleScroll = (event) => {
      const topPos = (element) => element.getBoundingClientRect().top;
      const div3Pos = topPos(animated.current);
      const scrollPos = window.scrollY;
      if (div3Pos + 300 < scrollPos) {
        path.current.setAttribute("class", "path");
        pathTablet.current.setAttribute("class", "pathTablet");
        // console.log(path.current.getTotalLength());
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container>
      <div className="animated-section" ref={animated}>
        <div className="line-container">
          <svg
            className="desktop-path"
            width="1008"
            height="1114"
            viewBox="0 0 1008 1114"
            fill="none"
          >
            <path
              className="invisible-path"
              ref={path}
              d="M506 0.5C368 183 148 -105 41 183C20.9233 237.038 9.5 308 90 391C170.5 474 393.5 443.5 396 408.5C398.5 373.5 328.5 390.5 368 426.5C407.5 462.5 476 483.5 580 413C684 342.5 788 319.5 899.5 399C1011 478.5 1030 617 984.495 690.5C938.995 764 819.997 843.5 497.496 752.5C-73.5235 591.375 -45.0024 961 62.4988 1065C170 1169 468.607 1077.95 655.5 1038.5C800.004 1008 940.5 987 949.5 1047.5C958.5 1108 874 1093 885 1061"
              stroke="#EBF3FA"
              fill="none"
              strokeWidth="2"
            />
          </svg>
          <svg
            className="tablet-path"
            width="698"
            height="888"
            viewBox="0 0 698 888"
            fill="none"
          >
            <path
              className="invisible-path tablet-path"
              ref={pathTablet}
              d="M333.351 1C239.056 143.21 84.499 15.5 15.6194 143.21C-5.40349 182.189 -10.2981 265.6 49.1 305.291C108.498 344.982 229.865 318.538 231.573 291.265C233.281 263.992 185.45 277.239 212.441 305.291C239.431 333.343 326.5 345 411.5 334C496.5 323 611.304 301.5 668.498 374C725.692 446.5 693.498 543.5 602.998 571C512.498 598.5 335.192 615.526 188.499 595C7.99936 569.743 -27.5 761.756 32.5 827.378C92.5 893 143.883 898.656 272.5 872C562 812 617.5 828.5 617.5 863C617.5 897.5 567.484 887.935 575 863"
              stroke="#EBF3FA"
              fill="none"
              strokeWidth="1.5"
            />
          </svg>
        </div>
        <Row>
          <Col lg={6} md={12} className="animated-image">
            <img src={customerIsHere} alt="Logo" loading="lazy" />
          </Col>
          <Col lg={6} md={12} className="animated-text">
            <h3>Make an appointment in the calendar</h3>
            <p>
              Our calendar is designed to be efficient and easy to use, allowing
              you to add, modify, and delete appointments with just a few
              clicks. It also gives you quick access to your clients'
              information, including their history, as soon as you enter their
              name or phone number.
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={12} className="animated-text middle">
            <h3>Croness automatically sends SMS and email</h3>
            <p>
              Your clients will never miss an appointment again thanks to
              automatic notifications! Messages can be customized as you wish,
              and in addition, clients have the option to confirm or cancel
              appointments by replying to the messages, and any canceled
              appointment will be automatically available for other clients.
            </p>
          </Col>
          <Col lg={6} md={12} className="animated-image">
            <img src={smsReceived} alt="sms-received" loading="lazy" />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={12} className="animated-image">
            <img src={customerIsHere} alt="customer-is-here" loading="lazy" />
          </Col>
          <Col lg={6} md={12} className="animated-text">
            <h3>Customer arrives at the scheduled time</h3>
            <p>
              The customer has all the necessary information and means of
              communication to be present at the scheduled time. The calendar
              will update automatically based on the customer's response, so you
              always have a clear picture and can avoid unpleasant surprises.
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Animated50;
