import { React, useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import Signup from "../pages/Signup";
import Login from "../pages/Login";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../styles/NavbarComp.css";
import logo from "../images/croness_logo.png";
import logoBlack from "../images/croness_black_logo_only.png";
import Button from "react-bootstrap/Button";
import TermsAndConditions from "./policies/TermsAndConditions";
import PrivacyPolicy from "./policies/PrivacyPolicy";
import CookiesPolicy from "./policies/CookiesPolicy";
import GDPRPolicy from "./policies/GDPRPolicy";
import home from "../images/navbar/home.png";
import prices from "../images/navbar/dollar-sign.png";
import contact from "../images/navbar/contact-form.png";
import login from "../images/navbar/user.png";

const NavbarComp = () => {
  const location = useLocation();
  const nav = useRef(null);
  const priceLink = useRef(null);
  const { pathname, hash } = location;
  // console.log(pathname, hash);
  const splitLocation = pathname.split("/");

  let navigate = useNavigate();
  const handleClick = (e) => {
    navigate("/#pricing");
    setActiveLink(e.target.id);
    setTimeout(() => document.getElementById("pricing").scrollIntoView(), 700);
    handleClose();
  };

  const [activeLink, setActiveLink] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClickNav = (e) => {
    setActiveLink(e.target.id);
    handleClose();
  };

  useEffect(() => {
    const element = document.getElementById(location.hash.substring(1));
    if (hash === "#pricing") {
      element.scrollIntoView();
    }

    const handleScroll = (event) => {
      const scrollPos = window.scrollY;
      const winWidth = window.innerWidth;

      if (scrollPos > 700) {
        nav.current.classList.add("sticky-nav");
        nav.current.classList.remove("sticky-nav-slide-up");
      } else if (scrollPos === 700) {
        nav.current.classList.add("sticky-nav-slide-up");
      } else if (scrollPos < 700) {
        nav.current.classList.remove("sticky-nav");
        nav.current.classList.remove("sticky-nav-slide-up");
      }

      if (winWidth <= 991 && scrollPos > 150) {
        nav.current.classList.add("sticky-nav");
        nav.current.classList.remove("sticky-nav-slide-up");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hash, location]);

  const goToTop = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setActiveLink(e.target.id);
    handleClose();
  };

  return (
    <div>
      <>
        {["md"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            ref={nav}
            className={splitLocation[1] === "" ? "" : "navbar-color"}
          >
            <Container>
              <Navbar.Brand as={Link} to="/" onClick={goToTop}>
                <img loading="lazy" src={logo} alt="croness-logo" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                onClick={handleShow}
              />

              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="top"
                show={show}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img
                      loading="lazy"
                      src={logoBlack}
                      alt="croness-logo-black"
                    />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="ms-auto">
                    <Link
                      to="/"
                      onClick={goToTop}
                      id="link1"
                      className={
                        activeLink === "link1"
                          ? "nav-link link activete"
                          : "nav-link link"
                      }
                    >
                      <img src={home} className="mobile-img" alt="acasă" />
                      Home
                    </Link>

                    <Nav.Link
                      href="#pricing"
                      onClick={handleClick}
                      id="link2"
                      className={
                        hash === "#pricing"
                          ? "pricing link activete"
                          : "pricing link"
                      }
                      ref={priceLink}
                    >
                      <img src={prices} className="mobile-img" alt="acasă" />
                      Pricing
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/contact"
                      onClick={handleClickNav}
                      id="link3"
                      className={
                        pathname === "/contact" ? "link activete" : "link"
                      }
                    >
                      <img src={contact} className="mobile-img" alt="acasă" />
                      Contact
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/sign-up"
                      onClick={goToTop}
                      id="link4"
                      className={activeLink === "link4" ? "" : ""}
                    >
                      <Button variant="primary">Try for Free</Button>{" "}
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/log-in" element={<Login />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/GDPR-policy" element={<GDPRPolicy />} />
        </Routes>
      </div>
    </div>
  );
};

export default NavbarComp;
