import React from "react";
import "../../styles/SubHeroSection.css";

const SubHeroSection = () => {
  return (
    <div>
      <div className="big-container">
        <div className="container">
          <div className="sub-hero-section">
            <h1>Schedule and notify automatically</h1>
            <p>
              Want to simplify scheduling and send automatic notifications to
              your clients to help them arrive on time? Our calendar helps you
              manage appointments, clients, employees, and services, all in one
              place!
              <br />
              How does CRONESS work?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeroSection;
