import React from "react";
import "../styles/SignUpAndLogin.css";
import { Container } from "react-bootstrap";

const Signup = () => {
  return (
    <div className="sign-up-and-login-form">
      <Container>
        <div class="form-title-sign">Incearca acum gratuit pentru 14 zile!</div>
        <span class="no-contract">
          Fara detalii bancare, fara contract, fara taxe ascunse.
        </span>
        <form className="sign-up-form">
          <div className="input-wrapper">
            <label htmlFor="email">Email</label>
            <span class="asterix">*</span>
            <input type="email" id="email" name="email" className="form-text" />
          </div>
          <div className="input-wrapper">
            <label htmlFor="username">Username</label>
            <span class="asterix">*</span>
            <input
              type="text"
              id="username"
              name="username"
              className="form-text"
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="password">Password</label>
            <span class="asterix">*</span>
            <input
              type="password"
              id="password"
              name="password"
              className="form-email"
            />
          </div>
          <input type="submit" value="Sign Up" className="form-submit" />
        </form>
      </Container>
    </div>
  );
};

export default Signup;
