import React from "react";
import Footer from "../components/Footer";
import Animated50 from "../components/home/Animated50";
import DetailsAndBenefits from "../components/home/DetailsAndBenefits";
import PriceCards from "../components/home/PriceCards";
import ServicesScroll from "../components/home/ServicesScroll";
import SubHeroSection from "../components/home/SubHeroSection";
import TryNowFree from "../components/home/TryNowFree";
import "../styles/Home.css";
import Hero from "./../components/home/Hero";

const Home = () => {
  return (
    <div>
      <Hero />
      <SubHeroSection />
      <Animated50 />
      <ServicesScroll />
      <PriceCards />
      <DetailsAndBenefits />
      <TryNowFree />
      <Footer />
    </div>
  );
};

export default Home;
