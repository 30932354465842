import { React, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../styles/Hero.css";
import heroImage from "../../images/demo-image.png";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Hero = () => {
  const phase =
    "For your business to thrive, it's essential that appointments are honored on time. That's why we created CRONESS. Your online calendar, which professionally and elegantly reminds your clients of their scheduled appointments, avoiding any issues related to delays.";
  const targetEl = useRef(null);

  useEffect(() => {
    phase.split("").forEach((char, idx) => {
      const el = document.createElement("span");

      el.innerText = char;
      el.setAttribute("data-index", idx.toString());
      el.classList.add("hover-char");

      targetEl.current.appendChild(el);
    });

    const hoverChar = [...document.getElementsByClassName("hover-char")];

    const removeClasses = () => {
      hoverChar.forEach((char) => {
        char.classList.remove("hovered");
        char.classList.remove("hovered-adjacent");
      });
    };

    hoverChar.forEach((char) => {
      char.addEventListener("mouseover", (e) => {
        removeClasses();

        const currentElement = e.target;
        const index = parseInt(currentElement.getAttribute("data-index"), 10);

        const prevIndex = index === 0 ? null : index - 1;
        const nextIndex = index === phase.length - 1 ? null : index + 1;

        const prevEl =
          prevIndex !== null &&
          document.querySelector(`[data-index="${prevIndex}"]`);
        const nextEl =
          nextIndex !== null &&
          document.querySelector(`[data-index="${nextIndex}"]`);

        e.target.classList.add("hovered");
        prevEl && prevEl.classList.add("hovered-adjacent");
        nextEl && nextEl.classList.add("hovered-adjacent");
      });
    });

    targetEl.current.addEventListener("mouseleave", () => {
      removeClasses();
    });
  }, []);

  return (
    <div className="containing-hero" id="home">
      <div className="container">
        <div className="hero">
          <Row>
            <Col lg={7} md={12} className="hero-text">
              <h2>Automatic notifications via SMS and email</h2>
              <h1 className="title">
                Appointment &nbsp;
                <br />
                Manager
              </h1>
              <p ref={targetEl}></p>
              <Link to="/sign-up">
                <Button variant="primary">Try for FREE </Button>{" "}
              </Link>
            </Col>
            <Col lg={5} md={12} className="hero-image">
              <img src={heroImage} alt="Logo" loading="lazy" />
            </Col>
          </Row>
        </div>
      </div>
      <div className="clip-path"></div>
    </div>
  );
};

export default Hero;
