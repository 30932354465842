import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import schedule from "../../images/benefits/schedule.png";
import notifications from "../../images/benefits/sms.png";
import confirmation from "../../images/benefits/confirmation.png";
import employees from "../../images/benefits/management.png";
import clients from "../../images/benefits/customer.png";
import list from "../../images/benefits/list.png";
import calendarSync from "../../images/benefits/calendar.png";
import devicesSync from "../../images/benefits/device.png";
import notes from "../../images/benefits/post-it.png";
import "../../styles/DetailsAndBenefits.css";

const DetailsAndBenefits = () => {
  return (
    <div className="benefit-container">
      <div className="container">
        <h1>Discover all the benefits</h1>
        <Row className="benefit-row">
          <Col lg={4} md={6} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img
                    src={schedule}
                    alt="Unlimited appointments"
                    loading="lazy"
                  />
                  <h3>Unlimited appointments</h3>
                </div>
                <Card.Text>
                  You can make as many appointments as you want, quickly and
                  easily. Our calendar has a modern interface and is designed to
                  easily manage all the details of your appointments, thus
                  offering precise and complete control over them.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img
                    src={notifications}
                    alt="Automatic notifications"
                    loading="lazy"
                  />
                  <h3>Automatic notifications</h3>
                </div>
                <Card.Text>
                  Choose between sending SMS, email or both to your customers.
                  Notifications are designed to remind without disturbing
                  customers. You can select between 1, 2, or 3 notifications per
                  appointment and also how long before they are sent.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img
                    src={confirmation}
                    alt="Customer confirmations"
                    loading="lazy"
                  />
                  <h3>Customer confirmations</h3>
                </div>
                <Card.Text>
                  Customers have the option to confirm, cancel or request
                  rescheduling of the meeting through messages, and the calendar
                  will automatically update to always provide the latest
                  information.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img
                    src={employees}
                    alt="Employee Management"
                    loading="lazy"
                  />
                  <h3>Employee Management</h3>
                </div>
                <Card.Text>
                  Here you will have the possibility to display appointments for
                  each employee separately, as well as adding or modifying
                  personal data, job title, or their profile picture.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img
                    src={clients}
                    alt="Customer Management"
                    loading="lazy"
                  />
                  <h3>Customer Management</h3>
                </div>
                <Card.Text>
                  Build customer loyalty by keeping everything in one place,
                  whether it's notes, photos, or documents. In their history,
                  you will find information about honoring or canceling previous
                  appointments, as well as the allocated time, cost, and service
                  provided.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img src={list} alt="Service List" loading="lazy" />
                  <h3>Service List</h3>
                </div>
                <Card.Text>
                  The service list helps you save valuable time by simply
                  choosing a service from a list when creating a new
                  appointment, and the duration and cost will be allocated
                  accordingly in the calendar.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img
                    src={calendarSync}
                    alt="Calendar Integration"
                    loading="lazy"
                  />
                  <h3>Calendar Integration</h3>
                </div>
                <Card.Text>
                  You have the option to synchronize Google Calendar and Apple
                  Calendar with our platform, to have them all at hand in one
                  place.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img
                    src={devicesSync}
                    alt="Device Sync"
                    loading="lazy"
                  />
                  <h3>Device Sync</h3>
                </div>
                <Card.Text>
                  Maximum flexibility! Use Croness anywhere, anytime, and on any
                  device or browser. Plus, any action is automatically synced
                  across all connected devices.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={12} sm={12}>
            <Card className="benefits">
              <Card.Body>
                <div className="icon-container">
                  <Card.Img src={notes} alt="Notes" loading="lazy" />
                  <h3>Notes</h3>
                </div>
                <Card.Text>
                  Whether you want to leave a reminder for a colleague or jot
                  something down quickly, notes are easily accessible from the
                  Croness dashboard, just 1 click away.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DetailsAndBenefits;
